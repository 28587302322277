<template>
    <div>
        <v-snackbar
            v-for="(snackBar, index) in snackBars.filter(s => s.showing)"
            :key="index"
            v-model="snackBar.showing"
            :timeout="snackBar.timeout"
            :color="snackBar.color"
            :style="`bottom: ${(index * 60) + 8}px`"
            right
            dark>
            {{snackBar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    @click="snackBar.showing = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: "SnackbarAlert",
    components: {
        
    },
    computed: {
        ...mapState('snackBar', ["snackBars"]) 
    },
}
</script>
